<template>
  <div class="xl:grid grid-flow-col grid-cols-1 grid-rows-1 lg:gap-x-32">

    <div data-accordion="collapse">
      <h2>
        <button type="button" @click="accordionData[0].open = !accordionData[0].open"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 "
          data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
          aria-controls="accordion-collapse-body-1">
          <span class="font-bold">Agent Info</span>
          <svg v-if="accordionData[0].open" data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <svg v-else data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div :class="accordionData[0].open ? '' : 'hidden'">
        <div class="p-5 font-light border border-b-0 border-gray-200">
          <!-- BEGIN: Roles -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'agent_roles_ids' })">
            <label class=" text-slate-600 font-bold">Agent Roles</label>
            <p class="text-left text-sm">{{ agent_info.agent_roles?.map(i => i.name)?.join(', ') ?? '—' }}</p>
          </div>
          <!-- END: Roles -->

          <!-- BEGIN: Leader name -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'leader_name' })">
            <label class=" text-slate-600 font-bold">Direct Upline</label>
            <p class="text-left text-sm">{{ agent_info.leader_names ?? '—' }}</p>
          </div>
          <!-- END: Leader name -->

          <!-- BEGIN: First Name -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'first_name' })">
            <label class=" text-slate-600 font-bold">First Name</label>
            <p class="text-sm text-left">{{ agent_info.first_name }}</p>
          </div>
          <!-- END: First Name -->

          <!-- BEGIN: Last Name -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'last_name' })">
            <label class=" text-slate-600 font-bold">Last Name</label>
            <p class=" text-sm text-left">{{ agent_info.last_name ?? '&mdash;' }}</p>
          </div>
          <!-- END: Last Name -->

          <!-- BEGIN: Title -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'title' })">
            <label class=" text-slate-600 font-bold">Title</label>
            <p class=" text-sm text-left">{{ agent_info.title ?? '&mdash;' }}</p>
          </div>
          <!-- END: Title -->

          <!-- BEGIN: Address -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'address' })">
            <label class=" text-slate-600 font-bold">Address</label>
            <p class=" text-sm text-left">{{ agent_info.address ?? '—' }}</p>
          </div>
          <!-- END: Address -->

          <!-- BEGIN: City -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'city' })">
            <label class=" text-slate-600 font-bold">City</label>
            <p class=" text-sm text-left">{{ agent_info.city ?? '—' }}</p>
          </div>
          <!-- END: City -->

          <!-- BEGIN: State -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'state_name' })">
            <label class=" text-slate-600 font-bold">State</label>
            <p class=" text-sm text-left">{{ agent_info.state_name ?? '—' }}</p>
          </div>
          <!-- END: State -->

          <!-- BEGIN: Zip -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'zip' })">
            <label class=" text-slate-600 font-bold">Zip</label>
            <p class=" text-sm text-left">{{ agent_info.zip ?? '—' }}</p>
          </div>
          <!-- END: Zip -->

          <!-- BEGIN: Email -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'email' })">
            <label class=" text-slate-600 font-bold">Email</label>
            <p class=" text-sm text-left">{{ agent_info.email ?? '—' }}</p>
          </div>
          <!-- END: Email -->

          <!-- BEGIN: Password -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer">
            <label class=" text-slate-600 font-bold">Password</label>
            <p class=" text-sm text-left"><em>Hidden</em></p>
          </div>
          <!-- END: Password -->

          <!-- BEGIN: Office Phone -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'office_phone' })">
            <label class=" text-slate-600 font-bold">Office Phone</label>
            <p class=" text-sm text-left">
              <input v-maska="'(###) ###-####'" :value="agent_info.office_phone" type="text"
                class="form-control w-full border-none shadow-none text-sm text-left px-0 py-0"
                style="background-color: transparent;" readonly>
            </p>
          </div>
          <!-- END: Office Phone -->

          <!-- BEGIN: Mobile Phone -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'mobile_phone' })">
            <label class=" text-slate-600 font-bold">Mobile Phone</label>
            <p class=" text-sm text-left">
              <input v-maska="'(###) ###-####'" :value="agent_info.mobile_phone" type="text"
                class="form-control w-full border-none shadow-none text-sm text-left px-0 py-0"
                style="background-color: transparent;" readonly>
            </p>
          </div>
          <!-- END: Mobile Phone -->

          <!-- BEGIN: Time zone -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'time_zone_name' })">
            <label class=" text-slate-600 font-bold">Time zone</label>
            <p class=" text-sm text-left">{{ agent_info.time_zone_name ?? '—' }}</p>
          </div>
          <!-- END: Time zone -->

          <!-- BEGIN: Billing Profile -->
          <!-- <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'billing_profile_name' })">
            <label class=" text-slate-600 font-bold">Billing Profile</label>
            <p class=" text-sm text-left">{{ agent_info.billing_profile_name ?? '—' }}</p>
          </div> -->
          <!-- END: Billing Profile -->
          
          <!-- BEGIN: Force Agent Pay -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
               @click="$emit('show-edit-mode', { fieldId: 'force_agent_pay' })">
            <label class=" text-slate-600 font-bold">Force Agent Pay</label>
            <p class=" text-sm text-left">{{ agent_info.force_agent_pay ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Force Agent Pay -->
        </div>
      </div>
      
      <h2>
        <button type="button" @click="accordionData[1].open = !accordionData[1].open"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 "
          data-accordion-target="#accordion-collapse-body-2" aria-expanded="false"
          aria-controls="accordion-collapse-body-2">
          <span class="font-bold">SMS Settings</span>
          <svg v-if="accordionData[1].open" data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <svg v-else data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div :class="accordionData[1].open ? '' : 'hidden'">
        <div class="p-5 font-light border border-b-0 border-gray-200 ">
          <div v-if="agentHasOwnTwilioAccountNumber">This agent has their own Twilio account set up.</div>

          <!-- BEGIN: Agent Owned Twilio Number -->
          <div v-if="agentHasOwnTwilioAccountNumber" class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
              @click="$emit('show-edit-mode', { fieldId: 'twilio_number' })">
            <label class=" text-slate-600 font-bold">Agent Owned Twilio Number</label>
            <p class=" text-sm text-left">{{ agent_info.agent_owned_twilio_number?.phone_number ?? '—' }}</p>
          </div>
          <!-- END: Agent Owned Twilio Number -->


          <!-- BEGIN: Twilio Number -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'twilio_number' })">
            <label class=" text-slate-600 font-bold">TopBroker Twilio Number</label>
            <p class=" text-sm text-left">{{ agent_info.twilio_phone_number_object?.phone_number ?? '—' }}</p>
          </div>
          <!-- END: Twilio Number -->

          <!-- BEGIN: Twilio Agency Name Override -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'sms_agency_name_override' })">
            <label class=" text-slate-600 font-bold">Twilio Agency Name Override</label>
            <p class=" text-sm text-left">{{ agent_info.sms_agency_name_override ?? '—' }}</p>
          </div>
          <!-- END: Twilio Agency Name Override -->

            <!-- BEGIN: Click To Call -->
            <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
                 @click="$emit('show-edit-mode', { fieldId: 'clicktocall' })">
                <label class=" text-slate-600 font-bold">Click To Call</label>
                <p class=" text-sm text-left">{{ agent_info.clicktocall ?? '—' }}</p>
            </div>
            <!-- END: Click To Call -->

          <!-- BEGIN: Alternate SMS Provider -->
         <!-- <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'alternate_sms_account_name' })">
            <label class=" text-slate-600 font-bold">Alternate SMS Provider</label>
            <p class=" text-sm text-left">{{ agent_info.alternate_sms_account_name ?? 'Disabled' }}</p>
          </div> -->
          <!-- END: Alternate SMS Provider -->

          <!-- BEGIN: Alternate SMS DID -->
          <!--<div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'alternate_sms_did' })">
            <label class=" text-slate-600 font-bold">Alternate SMS DID</label>
            <p class=" text-sm text-left">{{ agent_info.alternate_sms_did ?? '—' }}</p>
          </div>-->
          <!-- END: Alternate SMS DID -->

        </div>
      </div>


      <h2>
        <button type="button" @click="accordionData[2].open = !accordionData[2].open"
          class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 "
          data-accordion-target="#accordion-collapse-body-3" aria-expanded="false"
          aria-controls="accordion-collapse-body-3">
          <span class="font-bold">Other Settings</span>
          <svg v-if="accordionData[2].open" data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <svg v-else data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </h2>
      <div :class="accordionData[2].open ? '' : 'hidden'">
        <div class="p-5 font-light border border-t-0 border-gray-200 ">
          <!-- BEGIN: FFM User -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'ffm_user' })">
            <label class=" text-slate-600 font-bold">FFM User</label>
            <p class=" text-sm text-left">{{ agent_info.ffm_user ?? '—' }}</p>
          </div>
          <!-- END: FFM User -->

          <!-- BEGIN: FFM Pass -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'ffm_pass' })">
            <label class=" text-slate-600 font-bold">FFM Pass</label>
            <p class=" text-sm text-left">{{ agent_info.ffm_pass ?? '—' }}</p>
          </div>
          <!-- END: FFM Pass -->

          <!-- BEGIN: Working Hours -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'lock_working_hours' })">
            <label class=" text-slate-600 font-bold">Working Hours</label>
            <p class=" text-sm text-left">
              {{ agent_info.lock_working_hours ? 'Do NOT allow agent to modify hours' : 'Allow agent to modify hours' }}
            </p>
          </div>
          <!-- END: Working Hours -->

          <!-- BEGIN: Allow Search Exports -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_search_export' })">
            <label class=" text-slate-600 font-bold">Allow Search Exports</label>
            <p class=" text-sm text-left">{{ agent_info.allow_search_export ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Allow Search Exports -->

          <!-- BEGIN: Allow Create users -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_create_users' })">
            <label class=" text-slate-600 font-bold">Allow Create users</label>
            <p class=" text-sm text-left">{{ agent_info.allow_create_users ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Allow Create users -->

          <!-- BEGIN: Allow To Search Agency -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_search_agency' })">
            <label class=" text-slate-600 font-bold">Allow To Search Agency</label>
            <p class=" text-sm text-left">{{ agent_info.allow_search_agency ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Allow To Search Agency -->

          <!-- BEGIN: BDC Rep? -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'is_bdc' })">
            <label class=" text-slate-600 font-bold">BDC Rep?</label>
            <p class=" text-sm text-left">{{ agent_info.is_bdc ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: BDC Rep? -->

          <!-- BEGIN: Can Accept BDC Appt? -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'accept_bdc_appt' })">
            <label class=" text-slate-600 font-bold">Can Accept BDC Appt?</label>
            <p class=" text-sm text-left">{{ agent_info.accept_bdc_appt ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Can Accept BDC Appt? -->

          <!-- BEGIN: Allow to delete notes -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_delete_notes' })">
            <label class=" text-slate-600 font-bold">Allow to delete notes</label>
            <p class=" text-sm text-left">{{ agent_info.allow_delete_notes ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Allow to delete notes -->

          <!-- BEGIN: Allow agent to create Round Robin Groups -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_create_round_robin_groups' })">
            <label class=" text-slate-600 font-bold">Allow agent to create Round Robin Groups</label>
            <p class=" text-sm text-left">{{
                agent_info.allow_create_round_robin_groups ? 'Yes' : 'No'
            }}</p>
          </div>
          <!-- END: Allow agent to create Round Robin Groups -->

          <!-- BEGIN: Allow bad phone credits -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_bad_phone_credit' })">
            <label class=" text-slate-600 font-bold">Allow bad phone credits</label>
            <p class=" text-sm text-left">{{ agent_info.allow_bad_phone_credit ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Allow bad phone credits -->

          <!-- BEGIN: Allow already purchased credits -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_already_purchased_credit' })">
            <label class=" text-slate-600 font-bold">Allow already purchased credits</label>
            <p class=" text-sm text-left">{{
                agent_info.allow_already_purchased_credit ? 'Yes' : 'No'
            }}</p>
          </div>
          <!-- END: Allow already purchased credits -->

          <!-- BEGIN: Allow personal lead bucket text drip campaigns -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_bucket_responder' })">
            <label class=" text-slate-600 font-bold">Allow personal lead bucket text drip campaigns</label>
            <p class=" text-sm text-left">{{ agent_info.allow_bucket_responder ? 'Yes' : 'No' }}</p>
          </div>
          <!-- END: Allow personal lead bucket text drip campaigns -->

          <!-- BEGIN: Allow agent to go negative Credits -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
            @click="$emit('show-edit-mode', { fieldId: 'allow_negative_funds' })">
            <label class=" text-slate-600 font-bold">Allow agent to go negative Credits</label>
            <p class=" text-sm text-left">{{ agent_info.allow_negative_funds ?? '—' }}</p>
          </div>
          <!-- END: Allow agent to go negative Credits -->

          <!-- BEGIN: Grace Pulls -->
          <div class="py-2 border-b border-slate-100 lg:px-2 cursor-pointer"
               @click="$emit('show-edit-mode', { fieldId: 'grace_pull_override' })">
            <label class=" text-slate-600 font-bold">Grace Pulls</label>
            <p class=" text-sm text-left">{{ agent_info.grace_pull_override == '0' ? 'Active' : 'Override - Inactive' }}</p>
          </div>
          <!-- END: Grace Pulls -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentInfoReadOnly",

  props: {
    agent_info: {
      type: Object,
      required: true
    },
    agentHasOwnTwilioAccountNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      accordionData: [
        { key: "agent_info", open: true },
        { key: "sms_settings", open: false },
        { key: "other_settings", open: false },
      ],

    }
  },

  emits: ['show-edit-mode'],
}

</script>

<style scoped>

</style>
